import styled from 'react-emotion'

import t from 'src/theme'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'
import { Link } from 'src/components/Shared/Link/Link'
import { ReactComponent as AssetLogoSVG } from 'src/assets/logo.svg'
import AssetTextureUnderline from 'src/assets/texture-underline.svg'

export const Container = styled.div`
  background-color: ${t.c.green};
  color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  line-height: 1;
`

export const Content = styled.nav`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  padding: ${t.s(1.5)} 0 0;
  position: relative;
`

export const NavItems = styled.ul`
  font-size: ${t.f(-1)};
  letter-spacing: 0.1em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`

export const Item = styled.li`
  display: inline-block;
  list-style: none;
  margin: 0 ${t.s(2)} 0 0;

  &:last-child {
    margin-right: 0;
  }
`

export const ItemLink = styled(Link)`
  background-image: ${p =>
    p.isActive ? `url(${AssetTextureUnderline})` : 'none'};
  background-position: center 80%;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  padding: ${t.s(0, -4)};
  &:hover {
    text-decoration: none;
  }

  &:hover {
    background-image: url(${AssetTextureUnderline});
  }
`

export const LogoAspectRatio = styled(AspectRatio)`
  margin: 0 ${t.s(3)} ${t.s(1.5)};
  width: ${t.s(7)};
`

export const LogoIcon = styled(AssetLogoSVG)`
  display: block;
  fill: white;
  height: 100%;
  width: 100%;
`

export const SecondaryContent = styled.nav`
  align-items: center;
  border-top: 1px solid ${t.c.lightGreen};
  display: flex;
  justify-content: center;
`

export const SecondaryItems = styled(NavItems)`
  letter-spacing: 0;
  text-transform: none;
`
