import React from 'react'

import { ExtraLargeContentContainer } from 'src/components/Layout/Structure'
import { Container, Items } from './ChildNav.styled'

export const ChildNav = ({ children, isActive, ...props }) => (
  <Container isActive={isActive} {...props}>
    <ExtraLargeContentContainer>
      <Items>{children}</Items>
    </ExtraLargeContentContainer>
  </Container>
)
