import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { random } from 'lodash'
import { get, getOr, map, compose, filter, has, isEmpty } from 'lodash/fp'

import { getRichText } from 'src/helpers'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import {
  Container,
  Content,
  Message,
  Buttons,
  Button,
  ButtonText,
  Tape,
} from './MessageBar.styled'

export const MessageBar = () => {
  const queryData = useStaticQuery(graphql`
    {
      prismicSettings {
        data {
          notification_bar_text {
            html
            text
          }
          notification_bar_buttons {
            notification_bar_button_text {
              text
            }
            notification_bar_button_link {
              url
              target
            }
          }
        }
      }
    }
  `)

  const message = getRichText(
    'prismicSettings.data.notification_bar_text',
    queryData
  )
  const buttons = compose(
    filter(has('href')),
    map(button => ({
      href: get('notification_bar_button_link.url', button),
      target: get('notification_bar_button_link.target', button),
      text: getOr('Learn more', 'notification_bar_button_text.text', button),
    })),
    get('prismicSettings.data.notification_bar_buttons')
  )(queryData)

  if (!message) return null

  return (
    <Container>
      <LargeContentContainer>
        <Content>
          {message && <Message dangerouslySetInnerHTML={{ __html: message }} />}
          {!isEmpty(buttons) && (
            <Buttons>
              {map(
                (button, idx) => (
                  <Button
                    key={get('href', button)}
                    href={get('href', button)}
                    target={get('target', button)}
                  >
                    <Tape
                      color="pink"
                      rotation={random(-Math.abs(1), Math.abs(1))}
                    />
                    <ButtonText>{get('text', button)}</ButtonText>
                  </Button>
                ),
                buttons
              )}
            </Buttons>
          )}
        </Content>
      </LargeContentContainer>
    </Container>
  )
}
