import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  background-color: ${t.c.lightCream};
  color: ${t.c.gray};
  left: 0;
  display: ${p => (p.isActive ? 'block' : 'none')};
  padding: ${t.s(3, 0)};
  pointer-events: ${p => (p.isActive ? 'auto' : 'none')};
  position: absolute;
  right: 0;
  z-index: 1;
  box-shadow: 0px 2px 7px 0 rgba(0, 0, 0, 0.25);
`

export const Items = styled.ul`
  border: 2px solid ${t.c.gray};
  border-radius: ${t.r(-7)};
  display: flex;
  margin: 0;
  padding: ${t.s(-3)};
  position: relative;
`
