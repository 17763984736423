import React from 'react'

import { Container, StyledLink, Name, Description } from './ChildNavItem.styled'

export const ChildNavItem = ({ name, description, to, ...props }) => (
  <Container {...props}>
    <StyledLink to={to}>
      <Name>{name}</Name>
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </StyledLink>
  </Container>
)
