import styled from 'react-emotion'

import t from 'src/theme'
import { Mobile } from './Mobile'
import { Desktop } from './Desktop'

export const Container = styled.header`
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const StyledMobile = styled(Mobile)`
  ${t.mq.l} {
    display: none;
  }
`

export const StyledDesktop = styled(Desktop)`
  display: none;

  ${t.mq.l} {
    display: block;
  }
`
