import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'
import AssetTextureCircleLocations from 'src/assets/texture-circle-locations.svg'
import AssetTextureCircleMenus from 'src/assets/texture-circle-menus.svg'
import AssetTextureCircleGiftCards from 'src/assets/texture-circle-gift-cards.svg'
import AssetTextureCircleShop from 'src/assets/texture-circle-shop.svg'

export const Container = styled.li`
  display: inline-block;
  list-style: none;
  margin: 0;
`

export const StyledLink = styled(Link)`
  color: inherit;
  display: block;
  font-size: ${t.f(0.5)};
  letter-spacing: 0.1em;
  padding: ${t.s(1.5)} calc(${t.s(4)} / 2);
  position: relative;
  text-decoration: none !important;
`

const Circle = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  opacity: ${p => (p.isActive ? 1 : 0)};
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity calc(${t.t} / 2);

  ${Container}:hover &,
  ${StyledLink}:hover & {
    opacity: 1;
  }
`

export const CircleLocations = styled(Circle)`
  background-image: url(${AssetTextureCircleLocations});
`

export const CircleMenus = styled(Circle)`
  background-image: url(${AssetTextureCircleMenus});
`

export const CircleGiftCards = styled(Circle)`
  background-image: url(${AssetTextureCircleGiftCards});
  bottom: 10%;
`

export const CircleShop = styled(Circle)`
  background-image: url(${AssetTextureCircleShop});
`
