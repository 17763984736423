import React from 'react'

import { Container, StyledMobile, StyledDesktop } from './HeaderNav.styled'
import { MessageBar } from './MessageBar'

export const HeaderNav = ({ activePageName, resetMenu, ...props }) => (
  <Container {...props}>
    <StyledMobile />
    <StyledDesktop
      activePageName={activePageName}
      resetMenu={resetMenu}
      className="desktopNav"
    />
    <MessageBar />
  </Container>
)
