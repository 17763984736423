import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.li`
  font-size: ${t.f(-1)};
  font-weight: 300;
  list-style: none;
  margin: 0 0 ${t.s(-3)};
  padding: 0;

  &:last-child {
    margin-bottom 0;
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
