import styled from 'react-emotion'
import t from 'src/theme'

export const SmallContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${t.ms.s};
  width: 100%;
`

export const MediumContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${t.ms.m};
  width: 100%;
`

export const LargeContentContainer = styled.div`
  margin: 0 auto;
  max-width: ${t.ms.l};
  width: 100%;
`

export const ExtraLargeContentContainer = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${t.ms.x};
  width: 100%;
`
