import React from 'react'
import Component from '@reach/component-component'

import { ChildNav } from './ChildNav'
import {
  Container,
  StyledLink,
  CircleLocations,
  CircleMenus,
  CircleGiftCards,
  CircleShop,
} from './NavItem.styled'

const renderCircle = (name, activeName) => {
  switch (name) {
    case 'Locations':
      return <CircleLocations isActive={activeName === 'Locations'} />

    case 'Cookie Co.':
      return <CircleLocations isActive={activeName === 'cookies'} />

    case 'Menus':
      return <CircleMenus isActive={activeName === 'Menus'} />

    case 'Gift Cards':
      return <CircleGiftCards isActive={activeName === 'Gift Cards'} />

    case 'Shop':
      return <CircleShop isActive={activeName === 'Shop'} />

    default:
  }
}

export const NavItem = ({
  children,
  name,
  to,
  setState,
  activePageName,
  ...props
}) => (
  <Component initialState={{ active: false }}>
    {({ setState, state }) => (
      <Container
        className="nav-item"
        onMouseOver={() => setState({ active: true })}
        onMouseOut={() => setState({ active: false })}
        {...props}
      >
        <StyledLink to={to}>
          {name}
          {renderCircle(name, activePageName)}
        </StyledLink>
        {children && (
          <ChildNav className="nav-item__child" isActive={state.active}>
            {children({ hideNav: () => setState({ active: false }) })}
          </ChildNav>
        )}
      </Container>
    )}
  </Component>
)
