import React from 'react'
import Component from '@reach/component-component'

import { shopifyBuildUrl } from 'src/helpers'
import { Link } from 'src/components/Shared/Link/Link'
import { MobileNavItem } from './MobileNavItem'
import { MobileChildNavItem } from './MobileChildNavItem'
import {
  Container,
  Nav,
  Toggle,
  ToggleIconAspectRatio,
  ToggleIcon,
  ToggleIconCloseAspectRatio,
  ToggleIconClose,
  ToggleText,
  MobileNavItems,
  LogoContainer,
  LogoAspectRatio,
  Logo,
} from './Mobile.styled'

export const Mobile = props => (
  <Container {...props}>
    <Component initialState={{ active: false }}>
      {({ setState, state }) => (
        <Nav>
          <Toggle
            className="mobile-nav-toggle"
            isActive={state.active}
            onClick={() => setState({ active: !state.active })}
          >
            <ToggleIconCloseAspectRatio
              x={1}
              y={1}
              isActive={state.active}
              className="toggle-close"
            >
              <ToggleIconClose />
            </ToggleIconCloseAspectRatio>

            <ToggleIconAspectRatio
              x={12}
              y={9}
              isActive={state.active}
              className="toggle-icon"
            >
              <ToggleIcon />
            </ToggleIconAspectRatio>
            <ToggleText isActive={state.active} className="toggle-text">
              Menu
            </ToggleText>
          </Toggle>
          <MobileNavItems className="mobile-nav" isActive={state.active}>
            <MobileNavItem
              name="Locations"
              to="/#"
              onClick={() => setState({ active: false })}
            >
              <MobileChildNavItem
                name="Kailua Beach"
                to={shopifyBuildUrl('/kailua-beach')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Kailua Town"
                to={shopifyBuildUrl('/kailua-town')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Kapolei"
                to={shopifyBuildUrl('/kapolei')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Waimanalo"
                to={shopifyBuildUrl('/waimanalo')}
                onClick={() => setState({ active: false })}
              />
            </MobileNavItem>
            <MobileNavItem
              name="Menus"
              to={shopifyBuildUrl('/')}
              onClick={() => setState({ active: false })}
            >
              <MobileChildNavItem
                name="Kailua Beach"
                to={shopifyBuildUrl('/kailua-beach/#menu')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Kailua Town"
                to={shopifyBuildUrl('/kailua-town/#menu')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Kapolei"
                to={shopifyBuildUrl('/kapolei/#menu')}
                onClick={() => setState({ active: false })}
              />
              <MobileChildNavItem
                name="Waimanalo"
                to={shopifyBuildUrl('/waimanalo/#menu')}
                onClick={() => setState({ active: false })}
              />
            </MobileNavItem>
            <MobileNavItem
              name="Shop"
              to="https://shop.kalapawaimarket.com/"
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="Cookie Co."
              to={shopifyBuildUrl('/cookies/')}
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="About Us"
              to={shopifyBuildUrl('/about-us/')}
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="Gift Cards"
              to={shopifyBuildUrl('/gift-cards/')}
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="Employment"
              to={shopifyBuildUrl('/employment/')}
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="Get in Touch"
              to={shopifyBuildUrl('/contact/')}
              onClick={() => setState({ active: false })}
            />
            <MobileNavItem
              name="Kalapawai Wine Club"
              to="https://shop.kalapawaimarket.com/collections/kalapawai-wine-club"
              onClick={() => setState({ active: false })}
            />
          </MobileNavItems>
        </Nav>
      )}
    </Component>
    <LogoContainer>
      <LogoAspectRatio x={764.32} y={240.92}>
        <Link to="/">
          <Logo />
        </Link>
      </LogoAspectRatio>
    </LogoContainer>
  </Container>
)
