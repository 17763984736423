import React from 'react'

import { Container, StyledLink, ChildNavItems } from './MobileNavItem.styled'

export const MobileNavItem = ({ to, name, children, ...props }) => (
  <Container {...props}>
    <StyledLink to={to}>{name}</StyledLink>
    {children && <ChildNavItems>{children}</ChildNavItems>}
  </Container>
)
