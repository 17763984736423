import React from 'react'
import { Location } from '@reach/router'

import { isPathActive, shopifyBuildUrl } from 'src/helpers'
import { Link } from 'src/components/Shared/Link/Link'
import { NavItem } from './NavItem'
import { ChildNavItem } from './ChildNavItem'
import {
  Container,
  Content,
  NavItems,
  Item,
  ItemLink,
  LogoAspectRatio,
  LogoIcon,
  SecondaryContent,
  SecondaryItems,
} from './Desktop.styled'

export const Desktop = ({ activePageName, resetMenu, ...props }) => {
  return (
    <Container {...props}>
      <Content>
        <NavItems>
          <NavItem name="Locations" activePageName={activePageName}>
            {({ hideNav }) => (
              <>
                <ChildNavItem
                  name="Kailua Beach"
                  to={shopifyBuildUrl('/kailua-beach/')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Kailua Town"
                  to={shopifyBuildUrl('/kailua-town/')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Kapolei"
                  to={shopifyBuildUrl('/kapolei/')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Waimanalo"
                  to={shopifyBuildUrl('/waimanalo/')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
              </>
            )}
          </NavItem>
          <NavItem name="Menus" activePageName={activePageName}>
            {/*TODO Fix bullets on newline */}
            {({ hideNav }) => (
              <>
                <ChildNavItem
                  name="Kailua Beach"
                  description="Deli &bull; Breakfast &bull; Lunch"
                  to={shopifyBuildUrl('/kailua-beach/#menu')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Kailua Town"
                  description="Deli &bull; Breakfast &bull; Lunch<br/>Dinner &bull; Wine"
                  to={shopifyBuildUrl('/kailua-town/#menu')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Kapolei"
                  description="Deli &bull; Dinner &bull; Wine<br/>Happy Hour"
                  to={shopifyBuildUrl('/kapolei/#menu')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
                <ChildNavItem
                  name="Waimanalo"
                  description="Deli &bull; Breakfast &bull; Lunch<br/>Dinner"
                  to={shopifyBuildUrl('/waimanalo/#menu')}
                  onClick={e => {
                    hideNav()
                    resetMenu && resetMenu()
                  }}
                />
              </>
            )}
          </NavItem>
        </NavItems>
        <Link to={shopifyBuildUrl('/')}>
          <LogoAspectRatio x={764.32} y={240.92}>
            <LogoIcon />
          </LogoAspectRatio>
        </Link>
        <NavItems>
          <NavItem
            name="Shop"
            to="https://shop.kalapawaimarket.com/"
            activePageName={activePageName}
          />
          <NavItem
            name="Cookie Co."
            to={shopifyBuildUrl('/cookies/')}
            activePageName={activePageName}
          />
        </NavItems>
      </Content>
      <SecondaryContent>
        <SecondaryItems>
          <Location>
            {({ location }) => (
              <>
                <Item>
                  <ItemLink
                    to={shopifyBuildUrl('/about-us/')}
                    isActive={isPathActive(location, '/about-us/')}
                  >
                    About Us
                  </ItemLink>
                </Item>
                <Item>
                  <ItemLink
                    to={shopifyBuildUrl('/gift-cards/')}
                    isActive={isPathActive(location, '/gift-cards/')}
                  >
                    Gift Cards
                  </ItemLink>
                </Item>
                <Item>
                  <ItemLink
                    to={shopifyBuildUrl('/employment/')}
                    isActive={isPathActive(location, '/employment/')}
                  >
                    Employment
                  </ItemLink>
                </Item>
                <Item>
                  <ItemLink
                    to={shopifyBuildUrl('/contact/')}
                    isActive={isPathActive(location, '/contact/')}
                  >
                    Get in Touch
                  </ItemLink>
                </Item>
                <Item>
                  <ItemLink
                    to="https://shop.kalapawaimarket.com/collections/kalapawai-wine-club"
                    isActive={isPathActive(
                      location,
                      '/collections/kalapawai-wine-club'
                    )}
                  >
                    Kalapawai Wine Club
                  </ItemLink>
                </Item>
              </>
            )}
          </Location>
        </SecondaryItems>
      </SecondaryContent>
    </Container>
  )
}
