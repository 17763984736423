import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.li`
  list-style: none;
  margin: 0 0 ${t.s(0)};
  padding: 0;
`

export const StyledLink = styled(Link)`
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(0.5)};
  font-weight: 500;
  color: inherit;
  text-decoration: none;
`

export const ChildNavItems = styled.ul`
  margin: ${t.s(-2)} 0 ${t.s(2)};
  padding: 0;
`
