import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.li`
  line-height: ${t.lh.copy};
  border: 2px solid ${t.c.gray};
  border-left-width: 0;
  flex: 1 1 0px;
  list-style: none;
  margin: 0;
  padding: 0;

  &:first-child {
    border-left-width: 2px;
    border-top-left-radius: ${t.r(-8)};
    border-top-right-radius: ${t.r(-8)};

    ${t.mq.m} {
      border-top-right-radius: 0;
      border-bottom-left-radius: ${t.r(-8)};
    }
  }

  &:last-child {
    border-bottom-left-radius: ${t.r(-8)};
    border-bottom-right-radius: ${t.r(-8)};

    ${t.mq.m} {
      border-top-right-radius: ${t.r(-8)};
      border-bottom-left-radius: 0;
    }
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: ${t.s(2, 3)};
  text-align: center;
  text-decoration: none;
  transition-duration: ${t.t};
  transition-property: background-color, color;

  &:hover {
    background-color: ${t.c.gray};
    color: ${t.c.white};
  }
`

export const Name = styled.p`
  font-size: ${t.f(1)};
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
`

export const Description = styled.p`
  margin: 0.5rem 0 0 0;
  text-transform: none;
`
