import styled from 'react-emotion'

import t from 'src/theme'
import { AspectRatio } from 'src/components/Shared/AspectRatio/AspectRatio'
import { ReactComponent as AssetIconMenuSVG } from 'src/assets/icon-menu.svg'
import { ReactComponent as AssetIconCloseSVG } from 'src/assets/icon-close.svg'
import { ReactComponent as AssetLogoSVG } from 'src/assets/logo.svg'

export const Container = styled.div`
  background-color: ${t.c.green};
  color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  text-align: center;
`

export const Nav = styled.nav``

export const Toggle = styled.div`
  align-items: center;
  display: flex;
  font-size: ${t.f(-2)};
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: ${t.lh.solid};
  padding: ${t.s(0)};
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  background-color: ${t.c.green};
  cursor: pointer;
  border-bottom: 1px solid ${t.c.lightGreen};
  height: 1rem;
  box-sizing: content-box;
`

export const ToggleIconAspectRatio = styled(AspectRatio)`
  margin-right: ${t.s(-1)};
  width: ${t.s(0)};
  display: ${p => (p.isActive ? 'none' : 'block')};
`

export const ToggleIcon = styled(AssetIconMenuSVG)`
  display: block;
  height: 100%;
  width: 100%;
`

export const ToggleIconCloseAspectRatio = styled(AspectRatio)`
  width: ${t.s(0)};
  display: ${p => (p.isActive ? 'block' : 'none')};
`

export const ToggleIconClose = styled(AssetIconCloseSVG)`
  display: block;
  height: 100%;
  width: 100%;
`

export const ToggleText = styled.span`
  display: ${p => (p.isActive ? 'none' : 'block')};
`

export const MobileNavItems = styled.ul`
  margin: 0;
  padding: ${t.s(2, 0)};
  transform: translateY(${p => (p.isActive ? '0px' : '-110%')});
  pointer-events: ${p => (p.isActive ? 'auto' : 'none')};
  transition: transform, 0.5s ease;
  position: absolute;
  background-color: ${t.c.green};
  justify-content: center;
  z-index: 4;
  right: 0;
  left: 0;
  border-bottom: solid 1px ${t.c.lightGreen};

  li {
    opacity: ${p => (p.isActive ? '100%' : '0')};
    //delay opacity 0 for half a sec
    transition-delay: ${p => (p.isActive ? '0s' : '2s')};
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${t.s(1)};
`

export const LogoAspectRatio = styled(AspectRatio)`
  max-width: ${t.s(7)};
  width: 75%;
`

export const Logo = styled(AssetLogoSVG)`
  display: block;
  fill: ${t.c.white};
  height: 100%;
  width: 100%;
`
