import styled from 'react-emotion'

export const Container = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: calc(${p => p.y / p.x} * 100%);
    width: 100%;
  }
`

export const Inner = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`
