import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.div`
  background-color: ${t.c.lightGreen};
  color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-1)};
  padding: ${t.s(0, 1)};

  ${t.mq.m} {
    padding-left: ${t.s(3)};
    padding-right: ${t.s(3)};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Message = styled.div`
  margin: 0 0 ${t.s(-1)};
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -${t.s(-1)};
  margin-right: -${t.s(0)};
`

export const Button = styled(Link)`
  color: ${t.c.darkGray} !important;
  display: inline-block;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: ${t.s(-3, 1)};
  position: relative;
  text-transform: uppercase;
  transition: color ${t.t};
  white-space: nowrap;
  margin-right: ${t.s(0)};
  margin-bottom: ${t.s(-1)};

  &:hover {
    color: ${t.c.gray} !important;
  }
`

export const ButtonText = styled.span`
  position: relative;
`

export const Tape = styled.div`
  background-color: ${p => t.c[p.color]};
  content: '';
  display: block;
  position: absolute;
  transform: rotate(${p => `${p.rotation}deg`});
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`
